export default {
  SET_LIST_CATEGORY(state, payload) {
    console.log('payload', payload)
    state.listCategory = payload.data
  },
  SET_LIST_PRODUCT(state, payload) {
    console.log('payload', payload)
    state.listProduct = payload.data
  },
  SET_LIST_REGION(state, payload) {
    state.listRegion = payload.data
  },
  SET_LIST_CLUSTER(state, payload) {
    state.listCluster = payload.data
  },
  SET_LIST_ATTRIBUTE_BY_PRODUCT(state, payload) {
    state.listAttribute = payload.data.attributes
  },
}
