export default {
    SET_LIST_INVOICE(state, payload) {
        console.log('payload', payload)
        state.listInvoice = payload.data.data
    },
    SET_INVOICE(state, payload) {
        console.log('payload', payload)
        state.invoice = payload.data.data
    },
}
