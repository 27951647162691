import { axiosInstanceClient } from '@/helpers/axiosInstance'

export default {
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListInvoice({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        // Authorization: `Bearer ${state.accessToken}`,
      }
      await axiosInstanceClient.get('https://client-monmi.myepis.cloud/api/invoice/list/11ac670768d2555cf22137cc', { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          console.log('response getListInvoice', response)
          // If request is good...
          commit('SET_LIST_INVOICE', response)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getInvoice({ commit, state }, id) {
    try {
      const headers = {
        'Content-Type': 'application/json',
      }
      return await axiosInstanceClient.get('https://client-monmi.myepis.cloud/api/invoice-detail/11ac670768d2555cf22137cc/10', { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
}
