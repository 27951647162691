export default {
  SET_TOKEN_LOCAL_STORAGE(state, payload) {

  },
  SET_LOGIN_INFO(state, payload) {

  },
  SET_LIST_PROVINCE(state, payload) {
    state.listProvinces = payload.data
  },
  SET_LIST_DISTRICT(state, payload) {
    console.log('payload', payload)
    state.listDistricts = payload.data
  },
  SET_LIST_WARD(state, payload) {
    console.log('SET_LIST_WARD', payload)
    state.listWards = payload.data
  },
}
