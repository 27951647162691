<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Send Invoice
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- From -->
        <b-form-group
          label="From"
          label-for="from"
        >
          <b-form-input
            id="from"
            v-model="from"
            trim
            type="email"
            disabled=""
          />
        </b-form-group>

        <!-- To -->
        <b-form-group
          label="To"
          label-for="to"
        >
          <b-form-input
            id="to"
            v-model="to"
            trim
            type="email"
          />
        </b-form-group>

        <!-- Subject -->
        <b-form-group
          label="Subject"
          label-for="subject"
        >
          <b-form-input
            id="subject"
            v-model="subject"
            trim
          />
        </b-form-group>

        <!-- Message -->
<!--        <b-form-group-->
<!--          label="Message"-->
<!--          label-for="message"-->
<!--        >-->
<!--          <b-form-textarea-->
<!--            id="message"-->
<!--            v-model="msg"-->
<!--            rows="2"-->
<!--            trim-->
<!--          />-->
<!--        </b-form-group>-->

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click.prevent="sendMail()"
          >
            Send
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    invoice: { type: Object },
  },
  data() {
    return {
      to: '',
      from: 'nnoreply@monmi.episcloud.com',
      from_name: 'EPIS',
      msg: '',
      subject: 'Send Invoice',
    }
  },
  methods: {
    ...mapActions(['sendMailInvoice']),
    sendMail() {
      const data = {
        invoiceId: this.invoice.id,
        toMail: this.to,
        subject: this.subject,
        from: this.from,
      }
      this.sendMailInvoice(data)
    },
  },
}
</script>

<style>

</style>
